import { Box, Button, IconButton, TextField, Typography } from "@mui/material"
import Cfptb from "../../components/cfp/cfptb"
import { cfpColumns } from "../../components/utils/cfpcolumns"
import React, { useContext, useEffect, useState } from "react"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import Layout from "../../layouts"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { GlobalStateContext } from "../../context/GlobalContextProvider"
import CfpSimpleTable from "../../components/cfp/cfpsimpletable"
import CheckMemberTable from "../../components/attendance/checkMemberTable"
import { CfpSearch } from "../../components/cfp/cfpSearch"
export default function MemberGroup({ location }) {
  const [data, setData] = useState([])
  const [openHead, setopenHead] = useState(false)
  const [name, setName] = useState("")
  const [items, setItems] = useState([])
  const [memberItems, setMemberItems] = useState([])
  const [isGroup, setIsGroup] = useState(false)
  const { setCustomModalOpen, gCfp, setModalContent, gMemberDevice,gGroupDevice } =
    useContext(GlobalStateContext)
  useEffect(()=>{
    console.log("数据data变化",data)
  },[data])
  useEffect(() => {


    setMemberItems(gCfp.listMm)
  }, [gCfp.listMm])

  useEffect(() => {

console.log("请求设备",data,gMemberDevice.list.length)

    if (gMemberDevice.list.length>0){
      let  gids=gMemberDevice.list.map(item=>item.gid)
      gGroupDevice.getMemberGroup({
        op:'getm',
        list:gids
      },(d)=>{
        let  nresult=[]
        for (let i = 0; i <gMemberDevice.list.length ; i++) {
          let members=[]
          if (d[i].length>0){
            members=d[i].map(item=>{

              return {...item,memberGName:gGroupDevice.findMemberGroupName(item.did)}
            })
          }
          nresult.push({
            ...gMemberDevice.list[i],members:members
          })
        }
        console.log("gMemberDevice.list", nresult)
        setData(nresult)
      })

    }

   //setData(gMemberDevice.list)
  }, [gMemberDevice.list])
  useEffect(() => {
    if (name && items.length > 0) {
      setIsGroup(true)
    } else {
      setIsGroup(false)
    }



      setMemberItems(gCfp.listMm.filter(item=>!items.some(it=>it.subUUID===item.subUUID)))
  }, [name, items])
  const findSearchList = (items, key) => {
    return items.filter((item) => item.name.includes(key))
  }
  return (
    <>
      <Layout location={location}>
        <Box
          sx={{ display: "flex", flexDirection: "column", marginTop: "8px" }}
        >
          <CfpSearch
            callSearch={(e) => {

              console.log("开始搜索",e)
              if (e) {
                setData(findSearchList(gMemberDevice.list, e))
              } else {
                setData(gMemberDevice.list)
              }
            }}
          />
          {openHead && (
            <Box
              sx={{
                marginLeft: "20px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
                <Typography sx={{ fontSize: "22px", fontWeight: "bold" }}>
                  新規グループを追加
                </Typography>
                <IconButton
                  sx={{ marginRight: "10px" }}
                  onClick={() => {
                    setopenHead(false)
                  }}
                >
                  <KeyboardArrowDownIcon />
                </IconButton>
              </Box>
              <TextField
                size="small"
                id="outlined-basic"
                label="グループ名"
                variant="outlined"
                value={name}
                sx={{
                  width: "341px",
                  marginTop: "10px",
                  borderRadius: "6px",
                }}
                onChange={(e) => {
                  setName(e.target.value)
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  margin: "10px 0px 0px",
                }}
              >
                <Typography
                  variant="body1"
                  component="p"
                  style={{
                    fontFamily: "'Noto Sans JP', sans-serif",
                    fontSize: "18px",
                    fontWeight: 400,
                    lineHeight: "27px",
                    letterSpacing: "0.06em",
                    textAlign: "left",
                  }}
                >
                  {`ユーザー(${items.length})`}
                </Typography>
                <IconButton
                  sx={{ marginTop: "4px" }}
                  onClick={() => {
                    setCustomModalOpen(true)
                    setModalContent(
                      <CheckMemberTable
                        title={"ユーザーを選択"}
                        data={memberItems} //資料
                        setOpenModal={setCustomModalOpen} //開關Modal的屬性
                        handleCheck={(value) => {
                          setCustomModalOpen(false)
                          setItems((prevState) => [...prevState, ...value])
                        }} //勾選項目後要做的處理function
                        location={location}
                        isWifi={false}
                      />
                    )
                  }}
                >
                  <AddCircleIcon style={{ color: "#28AEB1" }} />
                </IconButton>
              </Box>
              <CfpSimpleTable
                items={items}
                btnDel={(item) => {

                  console.log("删除对象",item)
                  setItems((prevState) =>
                    prevState.filter(
                      (obj) => obj.subUUID !== item.subUUID
                    )
                  )
                }}
                name={"employeeName"}
              />
              <Button
                size="small"
                variant="outlined"
                disableElevation
                sx={{
                  width: "64px",
                  borderRadius: "6px",
                  color: "#28aeb1",
                  marginBottom: "30px",
                }}
                disabled={!isGroup}
                onClick={() => {
                  let ids = items.map((item) => item.subUUID)
                  gMemberDevice.addMemberDevice(name, ids, () => {
                    setName("")
                    setItems([])
                  })
                }}
              >
                登録
              </Button>
            </Box>
          )}
          <Cfptb
            callAdd={() => {
              setopenHead(true)
            }}
            isAdd={!openHead}
            data={data}
            isBind={false}
            isBack={false}
            path={"/employees/memberdetails"}
            text={`ユーザーグループ一覧`}
            columns={cfpColumns.membersGroup}
            callDelData={(items) => {
              let ids = items.map((item) => ({ gid: item.gid }))
              gMemberDevice.delsMemberDevice(ids,(objs)=>{


                setData(prevState => prevState.filter(item => !objs.some(obj => obj.gid === item.gid)));

              })
              //
            }}
          />
        </Box>
      </Layout>
    </>
  )
}
