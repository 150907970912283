import React, {useState } from "react"
import MUIDataTable from "mui-datatables"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import { Button, Box, Typography } from "@mui/material"
import {cfpColumns} from "../utils/cfpcolumns";


export default function ({
  title,
  data,
  setOpenModal,
  handleCheck,

}) {
  const [selected, setSelected] = useState([])


  const getMuiTheme = () =>
    createTheme({
      palette: {
        primary: {
          main: "#28aeb1",
        },
        info: {
          light: "#cccccc",
          main: "#666666",
        },
        error: {
          light: "#db807c",
          main: "#CC4A44",
        },
      },
      typography: {
        fontFamily: "'Noto Sans JP', sans-serif",
        h2: {
          fontSize: "22px",
          fontWeight: "bold",
        },
        h4: {
          fontSize: "16px",
        },
        h5: {
          fontSize: "14px",
        },
        button: {
          textTransform: "none",
        },
      },
      components: {
        MuiTypography: {
          styleOverrides: {
            root: {
              letterSpacing: "0.02rem",
            },
          },
        },
        MuiTableCell: {
          styleOverrides: {
            root: {},
          },
        },
      },
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            // backgroundColor: 'yellow',
          },
        },
      },
    })

  return (
    <Box>
      <ThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title={<Typography variant="h2">{title}</Typography>}
          columns={cfpColumns.groupMember}

          data={data}
          options={{

              responsive: "standard",
              filter: true,
              filterType: 'textField',
              search: false,
              print: false,
              download: false,
              viewColumns: false,
              customToolbar: null,

              elevation: 0,
              selectToolbarPlacement: "none",
              pagination: true,
              rowsPerPage: 10,
              rowsPerPageOptions: [5, 8, 10],
              selectableRowsOnClick: true,
              onRowSelectionChange: (currentSelect, allSelected, rowsSelected) => {
                setSelected(rowsSelected.map(index => data[index]))
              },
              setTableProps: () => {
                  return {
                      size: "small",
                  }
              },
          }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mt: "10px",
          }}
        >
          <Button
            size="small"
            sx={{ mr: "10px" }}
            onClick={() => {
              setOpenModal(false)
            }}
          >
            キャンセル
          </Button>
          <Button
            disableElevation
            size="small"
            variant="outlined"
            onClick={() => {
              handleCheck(selected)
            }}
          >
            確認
          </Button>
        </Box>
      </ThemeProvider>
    </Box>
  )
}
